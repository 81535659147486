﻿// JavaScript source code


export class Nyukai {
    constructor() {
        this.selectContents = ""//お申込み内容(数値)postするものではない
        this.storeID = "";
        this.storeName = ""//店舗名(postするものではない)
        this.memberName = "";
        this.memberKanaName = "";
        this.dateBirthDay = "";
        this.sex = "";
        this.zipCode = "";
        this.address1 = "";
        this.address2 = "";
        this.address3 = "";
        this.address4 = "";
        this.address = "";
        this.cellPhone = "";
        this.phoneMail = "";
        this.dateEnroll = "";
        this.cellPhone1 = "";
        this.cellPhone2 = "";
        this.cellPhone3 = "";
        this.payCatCD = ""; //支払区分 0:現金, 3:クレジット
        this.regiDiv = 2; //登録区分 1:店舗, 2:Web
        //M会員_SWIMMINGテーブル(スイミング特有補足事項)
        this.age = "";
        this.medHistory = "";
        this.swimmingExp = "";
        this.swimmingAbility = "";
        this.parentName = "";
        this.schoolName = "";
        this.schoolYear = "";
        //T仮売上伝票テーブル&T仮売上明細テーブル
        this.totalAmount = ""; //合計金額
        //入会金
        this.itemCD_EF = "";//入会金額
        this.itemName_EF = "";
        this.quantity_EF = "0";
        this.subtotalAmount_EF = "";
        this.salesUnitPrice_EF = "";
        //年会費
        this.itemCD_AF = "";
        this.itemName_AF = "";
        this.quantity_AF = "0";
        this.subtotalAmount_AF = "";
        this.salesUnitPrice_AF = "";
        //月会費
        this.itemCD_MF = "";
        this.itemName_MF = "";
        this.quantity_MF = "0";
        this.subtotalAmount_MF = "";
        this.salesUnitPrice_MF = "";
        //バス協力金
        this.itemCD_BF = "";
        this.itemName_BF = "";
        this.quantity_BF = "0";
        this.subtotalAmount_BF = "";
        this.salesUnitPrice_BF = "";
        //水着
        this.itemCD_SS = "";
        this.itemName_SS = "";
        this.quantity_SS = "0";
        this.subtotalAmount_SS = "";
        this.salesUnitPrice_SS = "";
        //キャップ
        this.itemCD_SC = "";
        this.itemName_SC = "";
        this.quantity_SC = "0";
        this.subtotalAmount_SC = "";
        this.salesUnitPrice_SC = "";
        //バッグ
        this.itemCD_SB = "";
        this.itemName_SB = "";
        this.quantity_SB = "0";
        this.subtotalAmount_SB = "";
        this.salesUnitPrice_SB = "";
        //連絡可能時間帯
        this.contactableHour = "";
        this.contactableHourName = "";
        //体験費用
        this.itemCD_TF = "";
        this.itemName_TF = "";
        this.quantity_TF = "0";
        this.subtotalAmount_TF = "";
        this.salesUnitPrice_TF = "";
        //体験コース
        this.ID_trial = "";//体験のID
        this.datetimeStart_trial = "";//2021/02/17 18:40:00
        this.datetimeEnd_trial = "";
        this.lessonCode = "";
        this.dayNum = "1";
        this.dayArray = ""//2/17;
        //入会週選択(postでは使わない)
        this.numWithinAWeek = "";
        //レッスン1日目
        this.ID_day1 = "";
        this.datetimeStart_day1 = "";
        this.datetimeEnd_day1 = "";
        //レッスン2日目
        this.ID_day2 = "";//ID
        this.datetimeStart_day2 = "";//2021/02/17 18:40:00
        this.datetimeEnd_day2 = "";//2021/02/17 18:40:00
        //バス利用状況
        this.datetimeBus_day1 = "";//2021/02/17 18:40:00
        this.statusBus_day1 = "";//statusCode
        this.datetimeBus_day2 = "";//2021/02/17 18:40:00
        this.statusBus_day2 = "";//statusCode
        //初回来店日
        this.ID_visit = "";
        this.datetimeStart_visit = "";
        this.datetimeEnd_visit = "";
        this.appliCatCD = "";//固定
}




    update(nyukai) {

        return nyukai;

    }
    updateLoginInfo(columnName, value) {
        this[columnName] = value;
    }
}

