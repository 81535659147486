// JavaScript source code


export class NyukaiReservation {
    constructor() {

        this.select = '';
        this.store = '';
        this.trial = '';
        this.selectweek = '1';
        this.bus1 = '';
        this.bus2 = '';
        this.week1 = '';
        this.week2 = '';
        this.visit = '';

 
    }


    update(nyukai) {

        return nyukai;

    }
    updateLoginInfo(columnName, value) {
        this[columnName] = value;
    }
}

// JavaScript source code
