// JavaScript source code


export class NyukaiConfirm {
    constructor() {


        this.terms = false;
        this.confirm1 = false;
        this.confirm2 = false;
        this.confirm3 = false;
        this.confirm4 = false;
        this.confirm5 = false;
        this.confirm6 = false;
        this.confirm7 = false;
        this.confirm8 = false;
        this.confirm9 = false;
        this.confirm10 = false;
        this.confirm11 = false;
        this.confirm12 = false;
        this.confirm13 = false;
        this.confirm14 = false;
        this.confirm15 = false;
        this.confirm16 = false;
        this.confirm17 = false;
        this.confirm18 = false;
        this.confirm19 = false;
        this.confirm20 = false;
        this.confirm21 = false;


    }


    update(nyukai) {

        return nyukai;

    }
    updateLoginInfo(columnName, value) {
        this[columnName] = value;
    }
}

// JavaScript source code
