// JavaScript source code


export class NyukaiPayment {
    constructor() {

        this.payment = "";
      
    }


    update(nyukai) {

        return nyukai;

    }
    updateLoginInfo(columnName, value) {
        this[columnName] = value;
    }
}

// JavaScript source code
