import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/taikai',
        name: 'Taikai',
        component: function () {
            return import('../views/Taikai.vue')
        }
    },
    {
        path: '/kyukai',
        name: 'Kyukai',
        component: function () {
            return import('../views/Kyukai.vue')
        }
    },
    
    {
        path: '/nyukai',
        name: 'Nyukai',
        component: function () {
            return import('../views/Nyukai.vue')
        }
    },
    /*{
        path: '/nyukai',
        name: 'NyukaiReservation',
        component: function () {
            return import('../views/NyukaiReservation.vue')
        }
    },
    {
        path: '/nyukaiConfirm',
        name: 'NyukaiConfirm',
        component: function () {
            return import('../views/NyukaiConfirm.vue')
        }
    },
    {
        path: '/nyukaiCustomer',
        name: 'NyukaiCustomer',
        component: function () {
            return import('../views/NyukaiCustomer.vue')
        }
    },
    {
        path: '/nyukaiPayment',
        name: 'NyukaiPayment',
        component: function () {
            return import('../views/NyukaiPayment.vue')
        }
    },*/

    {
        path: '/confirm',
        name: 'Confirm',
        component: function () {
            return import('../views/Confirm.vue')
        }
    }


]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
