// JavaScript source code


export class NyukaiCustomer {
    constructor() {

        this.lastname = "";
        this.firstname = "";
        this.lastkana = "";
        this.firstkana = "";
        this.seibetu = "";
        this.birthday = "";
        this.birthyear = "";
        this.birthmonth = "";
        this.birthdate = "";
        this.age = "";
        this.parentlastname = "";
        this.parentfirstname = "";
        this.tel = "";
        this.zip = "";
        this.email = "";
        this.schoolname = "";
        this.schoolnone = [];
        this.grade = "";
        this.experience = "";
        this.swimpower = "";
        this.swimpowercheck = [];
        this.region = "";
        this.locality = "";
        this.street = "";
        this.size = "";
        this.bus = "";
        this.accountnumber = "";


    }


    update(nyukai) {

        return nyukai;

    }
    updateLoginInfo(columnName, value) {
        this[columnName] = value;
    }
}

// JavaScript source code
